import React from "react";
import { Route, Switch } from "react-router-dom";

import { GuestRoute } from "./lib/GuestRoute";

import { Login } from "./views/Login/Login";
import { ResetPassword } from "./views/ResetPassword/ResetPassword";

import { MainApp } from "./components/MainApp/MainApp";

export const App = () => {
  return (
    <div className="App">
      <Switch>
        <GuestRoute path="/login" exact component={Login} />
        <GuestRoute path="/reset-password" exact component={ResetPassword} />

        <Route component={MainApp} />
      </Switch>
    </div>
  );
};
