import React from "react";
import { SidebarMainMenuItem } from "./SidebarMainMenuItem/SidebarMainMenuItem";

import "./SidebarMainMenu.scss";

export const SidebarMainMenu = () => {
  return (
    <ul className="SidebarMainMenu">
      <SidebarMainMenuItem title="General" path="/" />
      <SidebarMainMenuItem title="Dashboard" path="/dashboard" />
      <SidebarMainMenuItem title="RDX" path="/rdx" />
      <SidebarMainMenuItem title="Microservices" path="/microservices" />
    </ul>
  );
};
