import React, { ReactNode } from "react";

import "./CenteredBox.scss";

interface CenteredBoxProps {
  constant?: string;
  children: ReactNode;
}

export const CenteredBox = (props: CenteredBoxProps) => {
  return (
    <div className="CenteredBox d-flex flex-column justify-content-center w-100 pr-3 pl-3 align-items-center">
      {props.constant && <h4 className="mb-3">{props.constant}</h4>}
      <div className="CenteredBox__modal shadow rounded border p-4 d-flex flex-column align-items-center">
        {props.children}
      </div>
    </div>
  );
};
