import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { GrpcWebFetchTransport } from "@protobuf-ts/grpcweb-transport";

import { useRouter } from "../../../hooks/useRouter";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

import { AuthClient } from "../../../proto/internaltool";
import { AuthContext, AuthContextType, AuthMessage } from "./AuthContext";

interface ContextProps {
  children: React.ReactNode;
}

export const AuthContextProvider = (props: ContextProps) => {
  const {
    history,
    location: { pathname },
  } = useRouter();
  const [client, setClient] = useState<AuthClient | undefined>(undefined);
  const { setItem, getItem, removeItem } = useLocalStorage(
    "INTERNAL_TOOL_USER"
  );
  const [message, setMessage] = useState<AuthMessage>({
    type: undefined,
    text: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(() => {
    const localValue = getItem();

    return localValue || {};
  });
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const localValue = getItem();

    return localValue && Object.keys(localValue).length > 0;
  });

  useEffect(() => {
    initClient();
    initFirebase();
  }, []);

  useEffect(() => {
    return () => {
      setMessage(() => ({
        type: undefined,
        text: undefined,
      }));
    };
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const initFirebase = () => {
    if (firebase.apps.length) {
      return;
    }

    const config = {
      apiKey: "AIzaSyByR7NkjdITQsUf02YMWQVI-CI7TtO7ekc",
      authDomain: "platform2-dev-273013.firebaseapp.com",
    };
    firebase.initializeApp(config);

    firebase.auth().tenantId = "kutia-oypmj";

    setLoading(false);
  };

  const initClient = () => {
    const transport = new GrpcWebFetchTransport({
      format: "binary",
      baseUrl: "https://internaltools-gateway-ft3ogbbfma-ew.a.run.app:443",
    });

    setClient(new AuthClient(transport));
  };

  const loginUser = async (username: string, password: string) => {
    if (!client) {
      setMessage({
        type: "error",
        text:
          "Sorry, couldn't initialize the connection to the server. Please try again later!",
      });

      return;
    }

    setMessage({
      type: undefined,
      text: undefined,
    });
    setLoading(true);

    try {
      const {
        response: { tenantId },
      } = await client.getTenant({
        name: "kutia",
        email: username,
      });

      firebase.auth().tenantId = tenantId;

      await firebase.auth().signInWithEmailAndPassword(username, password);

      const user = {
        email: firebase.auth().currentUser?.email,
      };

      //   TODO: localstorage hook needs to be replaced
      setUser(user);
      setItem(user);
      setIsAuthenticated(true);

      history.replace("/");
    } catch (error) {
      console.error(error.message);

      setMessage({
        type: "danger",
        text: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setUser({});
    removeItem();
    setIsAuthenticated(false);
  };

  const resetPassword = async (email: string) => {
    setLoading(true);
    setMessage({
      type: undefined,
      text: undefined,
    });

    try {
      await firebase.auth().sendPasswordResetEmail(email);

      setMessage({
        type: "success",
        text:
          "Password reset was successful, please check your email with reset link!",
      });
    } catch (error) {
      console.error(error.message);

      setMessage({
        type: "danger",
        text: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const context: AuthContextType = {
    user,
    logout,
    loading,
    message,
    resetPassword,
    login: loginUser,
    isAuthenticated: isAuthenticated,
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
};
