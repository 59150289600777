import React, { useContext } from "react";

export interface AuthMessage {
  type: string | undefined;
  text: string | undefined;
}

export interface AuthContextType {
  user: any;
  loading: boolean;
  logout: () => void;
  message: AuthMessage;
  isAuthenticated: boolean;
  resetPassword: (email: string) => void;
  login: (username: string, password: string) => void;
}

const initialValues: AuthContextType = {
  user: {},
  loading: false,
  login: () => {},
  logout: () => {},
  message: {
    type: undefined,
    text: undefined,
  },
  isAuthenticated: false,
  resetPassword: () => {},
};

export const AuthContext = React.createContext<AuthContextType>(initialValues);

export const useAuthContext = () => useContext(AuthContext);
