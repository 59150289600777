// @generated by protobuf-ts 1.0.8
// @generated from protobuf file "internaltool.proto" (syntax proto3)
// tslint:disable
import { RpcTransport } from "@protobuf-ts/runtime-rpc";
import { MethodInfo } from "@protobuf-ts/runtime-rpc";
import { BinaryWriteOptions } from "@protobuf-ts/runtime";
import { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import { BinaryReadOptions } from "@protobuf-ts/runtime";
import { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import { UnaryCall } from "@protobuf-ts/runtime-rpc";
import { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * define messages
 *
 * @generated from protobuf message GetTenantRequest
 */
export interface GetTenantRequest {
    /**
     * The tenant to retrieve.
     * Format: tenants/{tenant}
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
}
/**
 * @generated from protobuf message Tenant
 */
export interface Tenant {
    /**
     * The resource name of the tenant
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The tenant id of the tenant
     *
     * @generated from protobuf field: string tenant_id = 2;
     */
    tenantId: string;
}
/**
 * @generated from protobuf service Auth
 */
export interface IAuthClient {
    /**
     * add RPC's
     *
     * @generated from protobuf rpc: GetTenant(GetTenantRequest) returns (Tenant);
     */
    getTenant(input: GetTenantRequest, options?: RpcOptions): UnaryCall<GetTenantRequest, Tenant>;
}
/**
 * Type for protobuf message GetTenantRequest
 */
class GetTenantRequest$Type extends MessageType<GetTenantRequest> {
    constructor() {
        super("GetTenantRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.resource_reference": { type: "auth/Tenant" }, "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTenantRequest>): GetTenantRequest {
        const message = { name: "", email: "" };
        if (value !== undefined)
            reflectionMergePartial<GetTenantRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTenantRequest): GetTenantRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTenantRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const GetTenantRequest = new GetTenantRequest$Type();
/**
 * Type for protobuf message Tenant
 */
class Tenant$Type extends MessageType<Tenant> {
    constructor() {
        super("Tenant", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tenant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Tenant>): Tenant {
        const message = { name: "", tenantId: "" };
        if (value !== undefined)
            reflectionMergePartial<Tenant>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Tenant): Tenant {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string tenant_id */ 2:
                    message.tenantId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Tenant, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string tenant_id = 2; */
        if (message.tenantId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tenantId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const Tenant = new Tenant$Type();
/**
 * @generated from protobuf service Auth
 */
export class AuthClient implements IAuthClient {
    readonly typeName = "Auth";
    readonly methods: MethodInfo[] = [
        { service: this, name: "GetTenant", localName: "getTenant", I: GetTenantRequest, O: Tenant, options: { "google.api.http": { get: "/v1/{name=tenants/*}" }, "google.api.method_signature": ["name"] } }
    ];
    constructor(private readonly _transport: RpcTransport) {
    }
    getTenant(input: GetTenantRequest, options?: RpcOptions): UnaryCall<GetTenantRequest, Tenant> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options), i = method.I.create(input);
        return stackIntercept<GetTenantRequest, Tenant>("unary", this._transport, method, opt, i);
    }
}
