import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useAuthContext } from "./context/AuthContext/AuthContext";

export const GuestRoute = (props: RouteProps) => {
  const authCtx = useAuthContext();

  if (authCtx.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
};
