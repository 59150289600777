import React from "react";
import { PageTitle } from "../../components/shared/PageTitle/PageTitle";

import "./General.scss";

export const General = () => {
  return (
    <div className="General">
      <PageTitle title="General" />
      <p>
        This dashboard is used internally from the GOGET team.
        <br /> In this dashboard we can deploy new versions of each project and
        also check if all microservices are up and running.
      </p>
    </div>
  );
};
