import React from "react";
import { NavLink } from "react-router-dom";
import { Button, Navbar } from "reactstrap";
import logo from "../../assets/images/logo.png";
import { useRouter } from "../../hooks/useRouter";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";

import "./Header.scss";

export const Header = () => {
  const { history } = useRouter();
  const authCtx = useAuthContext();

  const handleLogout = () => {
    authCtx.logout();

    history.replace("/login");
  };

  return (
    <div className="Header w-100 border-bottom p-4 pb-0">
      <Navbar expand="md" className="p-0">
        <NavLink className="Header__logo" to="/">
          <img src={logo} alt="GOGET" />
        </NavLink>
        <div className="ml-auto">
          <span className="mr-4">{authCtx.user.email}</span>
          <Button onClick={handleLogout} color="primary">
            Logout
          </Button>
        </div>
      </Navbar>
    </div>
  );
};
