import React from "react";

import "./MicroservicesHeader.scss";

export const MicroservicesHeader = () => {
  return (
    <div className="MicroservicesHeader w-100 mb-4 d-flex flex-column">
      <h1 className="mb-2">Microservices</h1>
      <h4>
        Last sync: <b>30 seconds ago</b>
      </h4>
    </div>
  );
};
