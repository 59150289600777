import { useFormik, FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup";

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .min(2, "Please enter a valid name")
    .required("Username is required"),
  password: Yup.string().required("Password is required"),
});

export interface LoginFields {
  username: string;
  password: string;
}

interface UseLoginFormOptions {
  initialValues?: LoginFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<LoginFields>
  ) => Promise<any>;
}

export type LoginFormik = ReturnType<typeof useLoginFormik>;

export const useLoginFormik = (opts: UseLoginFormOptions) => {
  return useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: LoginSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
